import React from "react";
import { format } from "date-fns";
import { StyledLogo } from "./StyledComponents";
import * as s from "../styles/globalStyles";

const getImagePath = (status) => {
  switch (status) {
    case 0: // paused
      return "Mint Is Paused";
    case 1: // allowlist
      return "Mint With Diablo Mint Pass";
    case 2: // public
      return "Allowlist Mint";
    case 3: // sold out
      return "Sold Out";
    default:
      return "Connect Your Wallet";
  }
};

const MintStatus = (props) => {
  const { status, date } = props;

  const text = getImagePath(status);

  return (
    <>
      <s.TextDescription
        style={{
          textAlign: "center",
          color: "var(--accent-text)",
          textTransform: "capitalize",
          fontFamily: "JarvishBlurry",
          fontSize: "100px",
          marginBottom: "10px",
        }}
      >
        {text}
      </s.TextDescription>
    </>
  );
};

export default MintStatus;
