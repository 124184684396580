import React from "react";
import { StyledButton } from "./StyledComponents";

const ConnectButton = (props) => {
  const { connect } = props;

  return (
    <StyledButton onClick={connect} value="Connect">
      Connect
    </StyledButton>
  );
};

export default ConnectButton;
