import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MintStatus from "./components/MintStatus";
import WhiteListedStatus from "./components/WhiteListedStatus";
import ConnectButton from "./components/ConnectButton";
import MintCount from "./components/MintCount";
import MintButton from "./components/MintButton";
import Cost from "./components/Cost";
import {
  ResponsiveWrapper,
  StyledImageButton,
  StyledLink,
} from "./components/StyledComponents";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import CONFIG from "./constants/config.json";
import AlreadyMintedCount from "./components/AlreadyMintedCount";
import useMint from "./hooks/useMint";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click mint to mint your NFTs.`);
  const [mintAmount, setMintAmount] = useState(1);
  const mint = useMint(setFeedback, setClaimingNft);
  const [walletIsWhitelisted, setWalletIsWhitelisted] = useState(null);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
    setMintAmount(1);
  }, [blockchain.account]);

  useEffect(() => {
    if (data.state === 1) {
      setMintAmount(data.passesCount);
    } else if (data.state === 2) {
      setMintAmount(1);
    }
  }, [data.allowance, data.numberMinted, data.state]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          padding: 24,
          backgroundColor: "var(--primary)",
          backgroundImage: "linear-gradient(265deg,#271e55,#03020e)",
        }}
      >
        <MintStatus
          status={data.state}
          date={new Date(CONFIG.MINT_TIMESTAMP * 1000)}
        />
        {data.state >= 2 || walletIsWhitelisted === null ? null : (
          <WhiteListedStatus isWhitelisted={walletIsWhitelisted} />
        )}
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>
          <s.SpacerLarge />
          <s.Container flex={2} jc={"center"} ai={"center"}>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
                fontFamily: "Inter",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>

            <AlreadyMintedCount
              state={data.state}
              allowance={data.passesCount}
              numberMinted={data.numberMinted}
            />
            <s.SpacerSmall />
            {data.state === 3 ? (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "Inter",
                  }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "Inter",
                  }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <div>
                  <StyledImageButton
                    src="/config/images/opensea.png"
                    onClick={() => window.open(CONFIG.OPENSEA_LINK)}
                  />
                  <StyledImageButton
                    src="/config/images/looksrare.png"
                    onClick={() => window.open(CONFIG.LOOKS_RARE_LINK)}
                  />
                </div>
              </>
            ) : (
              <>
                {data.state !== 2 ? (
                  <Cost mintAmount={mintAmount} state={data.state} />
                ) : null}
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <ConnectButton
                      connect={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    />
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontFamily: "Inter",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    {data.state !== 3 ? (
                      <>
                        <MintCount
                          showButtons={data.state !== 2}
                          disabled={data.state !== 1 && data.state !== 2}
                          mintAmount={mintAmount}
                          setMintAmount={setMintAmount}
                          max={data.passesCount}
                        />
                        <s.SpacerSmall />
                        <MintButton
                          onClick={(e) => {
                            if (claimingNft) return;
                            e.preventDefault();
                            mint(mintAmount);
                            getData();
                          }}
                          disabled={mintAmount === 0 || data.allowance === 0}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}

export default App;
