import React from "react";
import { StyledButton } from "./StyledComponents";

const MintButton = (props) => {
  const { onClick, disabled } = props;

  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      Mint
    </StyledButton>
  );
};

export default MintButton;
