import React from "react";
import * as s from "../styles/globalStyles";
import { StyledRoundButton } from "./StyledComponents";

const MintCount = (props) => {
  const { mintAmount, setMintAmount, disabled, max } = props;

  const canIncrement = !disabled && mintAmount < max;

  const canDecrement = !disabled && mintAmount > 0;

  const decrement = () => {
    if (canDecrement) {
      setMintAmount(mintAmount - 1);
    }
  };

  const increment = () => {
    if (canIncrement) {
      setMintAmount(mintAmount + 1);
    }
  };

  return (
    <s.Container ai={"center"} jc={"center"} fd={"row"}>
      {props.showButtons ? (
        <StyledRoundButton
          style={{ lineHeight: 0.4 }}
          disabled={!canDecrement}
          onClick={(e) => {
            e.preventDefault();
            decrement();
          }}
        >
          -
        </StyledRoundButton>
      ) : null}
      <s.SpacerMedium />
      <s.TextDescription
        style={{
          textAlign: "center",
          color: "var(--accent-text)",
          fontFamily: "Inter",
          fontSize: 32,
        }}
      >
        {mintAmount}
      </s.TextDescription>
      <s.SpacerMedium />
      {props.showButtons ? (
        <StyledRoundButton
          disabled={!canIncrement}
          onClick={(e) => {
            e.preventDefault();
            increment();
          }}
        >
          +
        </StyledRoundButton>
      ) : null}
    </s.Container>
  );
};

export default MintCount;
