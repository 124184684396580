import React from "react";
import * as s from "../styles/globalStyles";

const AlreadyMintedCount = (props) => {
  const { numberMinted, allowance, state } = props;

  if (state !== 1 && state !== 2) return null;

  return (
    <s.TextTitle
      style={{
        textAlign: "center",
        fontSize: 30,
        fontWeight: "bold",
        color: "var(--accent-text)",
        fontFamily: "Inter",
      }}
    >
      Minted: {numberMinted}
    </s.TextTitle>
  );
};

export default AlreadyMintedCount;
