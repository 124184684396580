import styled from "styled-components";

export const StyledButton = styled.button`
  font-family: Inter;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--secondary-text);
  width: 200px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :disabled {
    pointer-events: none;
    background-color: var(--disabled);
    border: 1px solid var(--disabled);
  }
  :hover {
    border: 1px solid white;
    box-shadow: 2px 2px 13px 2px hsla(0, 0%, 100%, 0.34);
    -webkit-box-shadow: 2px 2px 13px 2px hsla(0, 0%, 100%, 0.34);
    -moz-box-shadow: 2px 2px 13px 2px hsla(0, 0%, 100%, 0.34);
  }
`;

export const StyledLogo = styled.img`
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--accent);
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: #ffffff;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--accent);
  :disabled {
    pointer-events: none;
    background-color: var(--disabled);
    border: 1px solid var(--disabled);
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    border: 1px solid white;
    box-shadow: 2px 2px 13px 2px hsla(0, 0%, 100%, 0.34);
    -webkit-box-shadow: 2px 2px 13px 2px hsla(0, 0%, 100%, 0.34);
    -moz-box-shadow: 2px 2px 13px 2px hsla(0, 0%, 100%, 0.34);
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  color: var(--accent-text);
  font-family: Inter;
`;

export const StyledImageButton = styled.img`
  width: 50px;
  @media (min-width: 767px) {
    width: 50px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
  :hover {
    cursor: pointer;
  }
  margin: 10px;
`;
