import store from "../store";
import CONFIG from "../../constants/config.json";

const getWhitelistData = (address) => {
  return fetch(`${CONFIG.MERKLE_TREE_BACKEND_URL}/${address}`).then((res) =>
    res.json()
  );
};

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = store.getState().blockchain;

      const allowlistDataRequest = getWhitelistData(blockchain.account);

      let [
        state,
        totalSupply,
        numberMinted,
        passesCount,
        { proof, allowance },
      ] = await Promise.all([
        blockchain.smartContract.methods.state().call(),
        blockchain.smartContract.methods.totalSupply().call(),
        blockchain.smartContract.methods
          .numberMinted(blockchain.account)
          .call(),
        blockchain.mintPassContract.methods
          .balanceOf(blockchain.account, 0)
          .call(),
        allowlistDataRequest,
      ]);

      if (Number(totalSupply) === CONFIG.MAX_SUPPLY) {
        state = 3; // hard-coded sold out state
      }

      dispatch(
        fetchDataSuccess({
          state: Number(state),
          totalSupply: Number(totalSupply),
          numberMinted: Number(numberMinted),
          passesCount,
          proof,
          allowance,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
