import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import CONFIG from "../constants/config.json";

const useMint = (setFeedback, setClaimingNft) => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  // TODO:
  const mintWithPass = (mintAmount) => {
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mintWithPass(mintAmount)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .catch((e) => {
        // noop
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const allowlistMint = (mintAmount) => {
    let gasLimit = CONFIG.GAS_LIMIT;

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mintAllowList(1, data.allowance, data.proof)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .catch((e) => {
        // noop
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    return;
  };

  const mint = (amount) => {
    if (amount === 0) return;
    if (data.state === 1) {
      mintWithPass(amount);
    } else if (data.state === 2) {
      allowlistMint(amount);
    }
  };

  return mint;
};

export default useMint;
