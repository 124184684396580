import React from "react";
import * as s from "../styles/globalStyles";

const MintStatus = (props) => {
  const { isWhitelisted } = props;

  const text = isWhitelisted
    ? "Your wallet is registered for the pre-sale"
    : "Your wallet is not registered for the pre-sale";

  return (
    <s.TextDescription
      style={{
        textAlign: "center",
        color: "var(--accent-text)",
        textTransform: "capitalize",
        fontFamily: "Inter",
        fontSize: "30px",
      }}
    >
      {text}
    </s.TextDescription>
  );
};

export default MintStatus;
