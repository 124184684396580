import React from "react";
import * as s from "../styles/globalStyles";

const Cost = (props) => {
  const { mintAmount, state } = props;

  if (!state || state === 0 || state === 3) return null;

  return (
    <s.TextTitle
      style={{
        textAlign: "center",
        color: "var(--accent-text)",
        fontFamily: "Inter",
      }}
    >
      Burn {mintAmount} Mint Passes and Mint {mintAmount} Diablos
    </s.TextTitle>
  );
};

export default Cost;
